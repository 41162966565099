const paragraphs = [
    `The sun peeked over the horizon, casting a golden glow on the tranquil meadow below. Dewdrops glistened on the blades of grass, as if nature itself was awakening. A gentle breeze whispered through the trees, carrying with it the scent of wildflowers. Birds chirped merrily, their melodies harmonizing with the rustling leaves. It was a picturesque morning, a moment frozen in time. As I stood there, taking in the beauty of the world around me, a sense of peace washed over my soul. In this serene oasis, I felt connected to something greater, something beyond the chaos of everyday life.`,
    `The bustling city streets were alive with activity. Horns blared, and people hurriedly walked past one another, their faces buried in smartphones. The air was thick with the scent of exhaust fumes and street food. Neon signs illuminated the sidewalks, offering a glimpse into the vibrant nightlife that awaited after sundown. Tall buildings towered above, their glass windows reflecting the endless energy below. Amidst the chaos, I found myself caught up in the rhythm of the city, the pulse of urban existence. It was a place of constant motion, where dreams were forged and destinies were realized.`,
    `The old bookstore stood as a time capsule, a sanctuary for book lovers like myself. The musty smell of aged pages filled the air, evoking a sense of nostalgia. Shelves upon shelves were stacked with literary treasures, waiting to be discovered. The sound of crinkling paper and the soft turn of pages created a symphony of knowledge. Each book held a world within its covers, transporting readers to far-off lands and distant eras. I found solace in the quiet corners, surrounded by the written thoughts of countless minds. In this sanctuary of stories, time stood still, and imagination knew no bounds.`,
    `The waves crashed against the rugged cliffs, their foamy spray reaching towards the sky. Seagulls soared overhead, their cries carried by the salty breeze. I stood on the edge of the cliff, mesmerized by the vastness of the ocean. It stretched out before me, an endless expanse of blue that held mysteries untold. The rhythmic sound of the waves brought a sense of peace, as if nature itself was whispering ancient secrets. The power of the sea was humbling, a reminder of the vastness and beauty of our world. In that moment, I felt a deep connection to the ocean, as if its essence flowed through my veins.`,
    "The aroma of freshly brewed coffee filled the cozy café, enveloping me in its warm embrace. Soft jazz music played in the background, creating a soothing ambiance. People sat at tables, sipping their drinks and engrossed in conversation or lost in their own thoughts. Baristas skillfully crafted intricate latte art, turning each cup into a miniature masterpiece. The clinking of porcelain and the gentle hum of chatter blended together, creating a symphony of human connection. It was a haven of tranquility amidst the hustle and bustle of the outside world. In this little café, time seemed to slow down, allowing moments of pure bliss to be savored with each sip.",
    `The sun set in a blaze of colors, painting the sky with hues of orange, pink, and gold. Silhouettes of trees stood tall against the backdrop of the fading light. As the day made way for the night, a sense of calm settled over the landscape. Stars began to twinkle, one by one, as the moon rose to take its place. A blanket of tranquility enveloped the world, inviting dreams and contemplation. In the stillness of the evening, I found solace, a moment of respite from the demands of the day. The beauty of the twilight reminded me of the inherent magic that exists in the world around us.`,
    `The sound of laughter echoed through the park, carried on the playful breeze. Children ran freely, their imaginations guiding them on great adventures. Swings creaked back and forth, propelled by youthful energy. The scent of freshly cut grass mingled with the aroma of hot dogs from a nearby food stand. Families gathered, enjoying quality time together, their smiles radiant under the sun's warm embrace. It was a place of joy and innocence, where worries momentarily disappeared, and pure happiness reigned supreme.`,
    `Raindrops tapped gently against the windowpane, creating a soothing melody. The gray skies enveloped the world in a comforting embrace. I wrapped myself in a cozy blanket, settling into the corner of the couch with a good book in hand. The rhythmic sound of rain provided the perfect backdrop for a journey through the pages. Each turn brought new adventures, new emotions, and a temporary escape from reality. The rain outside seemed to wash away the weight of the day, leaving behind a sense of calm and renewal. In those quiet moments, lost in the magic of storytelling, I found solace and a connection to the infinite worlds contained within the written word.`,
    `The aroma of fresh-baked bread wafted from the corner bakery, enticing passersby with its warm embrace. Crispy crusts and fluffy insides awaited those who stepped through the door. Display cases were filled with an array of pastries and cakes, each a work of edible art. The sound of clinking plates and muffled conversations created a symphony of culinary delight. From the first bite, flavors danced on the tongue, invoking memories of comfort and happiness. In this little bakery, time stood still as taste buds rejoiced in the simple pleasure of indulgence.`,
    `The mountaintop offered a breathtaking vista, as if the whole world lay at my feet. Snow-capped peaks stretched toward the heavens, their majestic presence a testament to nature's grandeur. The air was crisp and invigorating, filling my lungs with a sense of vitality. Far below, valleys and rivers painted a patchwork of green and blue. It was a place of serenity and perspective, where the magnitude of the universe felt within grasp. Standing there, I couldn't help but feel a profound connection to the Earth, and a humble reminder of my place in the vast tapestry of existence.`,
  
    `The clock struck twelve, signaling the arrival of a new day. The city streets, once bustling with activity, now lay shrouded in silence. Neon signs flickered, casting eerie shadows on the pavement. It was a time when dreams and nightmares danced hand in hand. As the minutes ticked by, the world seemed to hold its breath, caught between yesterday's memories and tomorrow's possibilities. In this midnight hour, the darkness revealed its secrets, and the stars above whispered tales of mystery and enchantment.`,
  
    `She counted down the days on her calendar, anticipation building with each X she made. Twenty-four days remained until her long-awaited vacation. The thought of sandy beaches, warm sunrays, and a break from routine filled her with excitement. She imagined herself lounging by the pool, a refreshing drink in hand, and not a worry in sight. The countdown served as a reminder that adventure awaited, a well-deserved escape from the monotony of everyday life.`,
  
    "The vision of an attempt becomes a lawny output. Dibbles are mis womens. The olden penalty reveals itself as a bustled field to those who look. Few can name a chalky force that isn't a primate literature. However, they were lost without the gamy screen that composed their beret. Nowhere is it disputed that a step-uncle is a factory from the right perspective. One cannot separate paints from dreary windows. What we don't know for sure is whether.",
  
    `The weight of his mistakes bore heavy on his shoulders, like a burden he couldn't shake. Six missed opportunities, six wrong turns that led him astray. Regret crept in, whispering reminders of what could have been. But he refused to be defined by his past failures. With determination in his eyes, he vowed to make the seventh time count. With each step forward, he embraced the lessons learned, knowing that sometimes the hardest battles lead to the sweetest victories.`,
  
    `The group of friends huddled around the campfire, their voices rising and falling in laughter and camaraderie. They reminisced about their shared adventures, from climbing Mount Everest to skydiving over the Grand Canyon. Each story was punctuated by cheers and high-fives, as they celebrated the memories made. They were bound by a bond that couldn't be broken, a friendship forged through nine years of shared experiences. In that moment, they knew that no matter where life took them, they would always be connected by the shared numeral of their journey. `,
  
    `The race began, and the runners sprinted off the starting line, driven by the desire to conquer the course ahead. The pounding of their feet against the pavement echoed in rhythm with their racing hearts. Mile after mile, they pushed themselves, their bodies aching for the finish line. Three more miles to go, and exhaustion threatened to overcome them. But their determination was unwavering. With each stride, they chanted a silent mantra, fueling their resolve. And as they crossed the finish line, their bodies drenched in sweat, they knew that the number on the clock didn't define their victory—rather, it was the strength, perseverance, and indomitable spirit that carried them to the end.`,
  
    `As she sat at her desk, staring at the blank page before her, the cursor blinked mockingly, daring her to begin. Thoughts swirled in her mind, a jumble of ideas and possibilities. She took a deep breath and reminded herself of the cardinal rule: just write. One word. Two words. Slowly, the story took shape. Characters emerged, each with their own quirks and dreams. The plot unraveled, twisting and turning like a rollercoaster ride. And before she knew it, the page transformed into a canvas, painted with words and punctuation marks, a symphony of storytelling. `,
  
    `The mathematician pondered the equation before him, its complexity taunting him. He reached for his trusted calculator, a tool that had seen countless calculations over the years. The numbers danced across the screen, symbols of the universe's hidden patterns. One plus one equaled two, a simple truth that formed the foundation of his work. But as he delved deeper into the equation, he realized that mathematics was more than just numbers and symbols—it was a language that unlocked the secrets of the universe, connecting the dots and revealing the harmonious symphony that governed all things.`,
  
    "A reptant discussion's rest comes with it the thought that the condemned syrup is a wish. The drake of a wallaby becomes a sonant harp. If this was somewhat unclear, spotty children show us how technicians can be jumps. Their honey was, in this moment, an intime direction. A ship is the lion of a hate. They were lost without the croupous jeep that composed their lily. In modern times a butcher of the birth is assumed to be a spiral bean.",
  
    "Those cowbells are nothing more than elements. This could be, or perhaps before stockings, thoughts were only opinions. A coil of the exclamation is assumed to be a hurtless toy. A board is the cast of a religion. In ancient times the first stinko sailboat is, in its own way, an exchange. Few can name a tutti channel that isn't a footless operation. Extending this logic, an oatmeal is the rooster of a shake. Those step-sons are nothing more than matches.",
  
    `But he recalled how many pins had been inserted in the shirt and thought, "Losing one won't matter. "Philip started to play. At first his right arm moved back and forth slowly, then more swiftly. Before long the pins that were holding his collar pulled out, The loose, large shirt collar began to creep up the back of Philip's head. Then the unruly sleeves grew looser and longer. Suddenly the shirt fell away from his neck. The audience began to laugh. In embarrassed confusion, Philip forgot what he was playing and stopped completely. The disaster so upset him that he rushed off the stage and sulked in a dark corner. Fighting back tears, he mumbled gloomily, "I wish I were dead Refreshments were served after the concert, but Philip was too busy to have any. He mingled with the crowd as quickly as he could, hoping to avoid Mr. Esputa. After a wistful look at the ice cream, Phillip was about to slink out when a booming voice behind him scoffed, "Well, Philip, you made a nice mess of it. " Philip turned and found himself face to face with his glowering teacher. With no sympathy for poor Philip`,
  
    ` The orchestra conductor stood before the musicians, his baton poised in the air. The room fell silent, and with a swift flick of his wrist, the performance began. Violins sang, their strings vibrating with passion. Trumpets blared, punctuating the melody. The symphony unfolded, a masterful composition of sound and emotion. The conductor's hands moved in fluid motions, guiding the musicians through the intricate maze of notes. And as the final note hung in the air, the audience erupted in applause, a testament to the power of unity and the beauty that can be created when disparate elements come together as one`,
  
    `The teacher scribbled equations on the whiteboard, her chalk gliding effortlessly across the surface. Symbols and numbers formed a dance of logic and reasoning. The students furiously took notes, their pens scratching against paper. Two plus two equaled four, a fundamental truth that paved the way for more complex concepts. The classroom hummed with intellectual energy, as young minds grappled with the mysteries of mathematics. And in that moment, the teacher realized that she held the key to unlocking the potential within each student—a passion for learning, a spark that could ignite a lifelong journey of discovery.
    `,
    ` The city skyline at night was a tapestry of lights, a dazzling display of human ingenuity. Neon signs flickered and glowed, casting a kaleidoscope of colors onto the streets below. Buildings reached for the stars, their silhouettes punctuated by glowing windows. And amidst the urban symphony, street artists left their mark—a swirl of paint here, a stencil there. Their creations breathed life into the concrete jungle, turning mundane walls into galleries of creativity. The cityscape became a canvas, a visual feast for those who dared to look beyond the surface.`,
  ];
  export default paragraphs;
  